import { FormInstance, Upload } from 'antd'
import classes from './ImagesField.module.css'
import { ReactComponent as UploadIcon } from './../../../../img/airportDetailsForm/uploadIcon.svg'
import { useState } from 'react'
import { ReactComponent as DeleteIcon } from './../../../../img/icons/delete.svg'
import { useAppSelector } from '../../../../app/hooks'
import { selectCurrentRental } from '../../../../store/rentalReducer'
import { beforeUpload, dummyRequest, getImageUrl, normFile } from '../../../../helpers/files_helper'

const ImagesField:React.FC<{form: FormInstance}> = ({form}) => {
  const currentRental = useAppSelector(selectCurrentRental)
  const defaultMaxCount = 10

  const [newPreviews, setNewPreviews] = useState<{url: string, uid: string}[]>([])
  const [previews, setPreviews] = useState<string[]>(currentRental?.photo_urls || [])

  const addImage = async(file:any) => {
    const currentVal = form.getFieldValue('add_photos')
    if (!currentVal?.some((v: any) => v.uid === file.uid)) {
      form.setFieldsValue({add_photos: [...(currentVal?.length ? currentVal : []), file]})
      const imageUrl = await getImageUrl(file)
      setNewPreviews([...newPreviews, {url: imageUrl, uid: file.uid}])
    }
  }

  const deletePhoto = (urlUid: string, isNew: boolean) => {
    if (isNew) {
      const added = form.getFieldValue('add_photos')
      const updatedAdded = added.filter((i:any) => i.uid !== urlUid)
      form.setFieldValue('add_photos', updatedAdded)
      setNewPreviews(newPreviews.filter((i:any) => i.uid !== urlUid))
    } else {
      setPreviews(previews.filter((i:any) => i !== urlUid))
      const deleted = form.getFieldValue('delete_photos') || []
      form.setFieldValue('delete_photos', [...deleted, urlUid])
    }
  }

  return (
    <div className={`${classes.formBlock} ${classes.photoList}`}>
      <Upload.Dragger
        name='files'
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        accept={'image/png, image/jpeg'}
        maxCount={defaultMaxCount}
        onChange={(e) => normFile(e, addImage)}
        showUploadList={false}
      >
        <UploadIcon />
        <div>
          Click or drag photo to this area to upload
        </div>
      </Upload.Dragger>
      {(newPreviews || [])?.toReversed()?.map((image:any) => (
        <ImageWithOptions url={image.url} uid={image.uid} key={image.uid} deletePhoto={deletePhoto}/>
      ))}
      {previews.map(url => (
        <ImageWithOptions url={url} key={url} deletePhoto={deletePhoto}/>
      ))}
    </div>
  )
}

const ImageWithOptions:React.FC<ImageWithOptionsPropTypes> = ({url, uid, deletePhoto}) => {
  return (
    <div className={classes.imageWithOptions}>
      <div className={classes.options}>
        <DeleteIcon onClick={() => deletePhoto(uid || url, !!uid)} />
      </div>
      <img src={url} alt='' className={classes.photo}/>
    </div>
  )
}

interface ImageWithOptionsPropTypes {
  url: string
  uid?: string
  deletePhoto: (urlUid: string, isNew: boolean) => void
}

export default ImagesField
