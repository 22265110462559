import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { blogAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { AboutPageType, BlogEntriesFetchParams, BlogEntryType, BlogFormImageType, BlogImageType, NewBlogEntryType } from '../types/blogTypes'

const blogEntriesFetchParamsDefaultValue = {
  pagination_request: {page: 1, size: 10 as 10},
  blog_entity_request: {search: ''}
}

interface InitialStateType {
  blogEntries: null | BlogEntryType[]
  blogEntriesTotalCount: number
  blogEntriesFetchParams: BlogEntriesFetchParams
  currentBlogEntry: null | BlogEntryType

  aboutPage: null | AboutPageType
}

const initialState: InitialStateType = {
  blogEntries: [],
  blogEntriesTotalCount: 0,
  blogEntriesFetchParams: blogEntriesFetchParamsDefaultValue,
  currentBlogEntry: null,

  aboutPage: null,
}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogEntries: (state, action: PayloadAction<null | BlogEntryType[]>) => {state.blogEntries = action.payload},
    setBlogEntriesTotalCount: (state, action: PayloadAction<number>) => {state.blogEntriesTotalCount = action.payload},
    setBlogEntriesFetchParams: (state, action: PayloadAction<BlogEntriesFetchParams>) => {state.blogEntriesFetchParams = action.payload},
    setCurrentBlogEntry: (state, action: PayloadAction<BlogEntryType | null>) => {state.currentBlogEntry = action.payload},
  
    setAboutPage: (state, action: PayloadAction<AboutPageType>) => {state.aboutPage = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBlogEntriesThunk.fulfilled, (state, action) => {
        state.blogEntries = action.payload.blog_entries
        state.blogEntriesTotalCount = action.payload.total_count
      })
      .addCase(GetBlogEntryByIdThunk.fulfilled, (state, action) => {
        state.currentBlogEntry = action.payload
      })
      .addCase(CreateBlogEntryThunk.fulfilled, (state, action) => {
        state.blogEntries = [...state.blogEntries || [], action.payload]
      })
      .addCase(EditBlogEntryThunk.fulfilled, (state, action) => {
        state.blogEntries = state.blogEntries?.map(entry => {
          return entry.id === action.payload.id ? action.payload : entry
        }) || []
      })
      .addCase(DeleteBlogEntryThunk.fulfilled, (state, action) => {
        state.blogEntries = state.blogEntries?.filter(entry => entry.id !== action.payload) || []
      })
      .addCase(AddImagesToBlogEntryThunk.fulfilled, (state, action) => {
        state.blogEntries = state?.blogEntries?.map(entry => entry.id === action.payload.entryId ? {...entry, blog_images: [...entry?.blog_images || [], ...action.payload.imageData]} : entry) || []
      })
      .addCase(DeleteBlogEntryImagesThunk.fulfilled, (state, action) => {
        state.blogEntries = state?.blogEntries?.map(entry => entry.id === action.payload.entryId ? {...entry, blog_images: entry.blog_images.filter(i => !action.payload.imageIds?.includes(i.id))} : entry) || []
      })
      .addCase(GetAboutPageThunk.fulfilled, (state, action) => {
        state.aboutPage = action.payload
      })
      .addCase(CreateAboutPageThunk.fulfilled, (state, action) => {
        state.aboutPage = action.payload
      })
      .addCase(UpdateAboutPageThunk.fulfilled, (state, action) => {
        state.aboutPage = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.blogEntries = null
        state.blogEntriesTotalCount = 0
        state.blogEntriesFetchParams = blogEntriesFetchParamsDefaultValue
        state.aboutPage = {} as AboutPageType
      })
  }
})

export const {
  setBlogEntries,
  setBlogEntriesTotalCount,
  setBlogEntriesFetchParams,
  setCurrentBlogEntry,
  setAboutPage,
} = blogSlice.actions

export const selectBlogEntries = (state: RootState): null | BlogEntryType[] => state.blog.blogEntries
export const selectBlogEntriesTotalCount = (state: RootState): number => state.blog.blogEntriesTotalCount
export const selectBlogEntriesFetchParams = (state: RootState): BlogEntriesFetchParams => state.blog.blogEntriesFetchParams
export const selectCurrentBlogEntry = (state: RootState): BlogEntryType | null => state.blog.currentBlogEntry
export const selectAboutPage = (state: RootState): null | AboutPageType => state.blog.aboutPage

export const GetAllBlogEntriesThunk = createAsyncThunk<{blog_entries: BlogEntryType[], total_count: number}, {fetchParams: BlogEntriesFetchParams, source: any}, AsyncThunkConfig>(
  'blog/getAllBlogEntries',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('pagination_request', new Blob([JSON.stringify(fetchParams.pagination_request, null, 2)], {type: 'application/json'}))
      formData.append('blog_entity_request', new Blob([JSON.stringify(fetchParams.blog_entity_request, null, 2)], {type: 'application/json'}))
      const { status, data } = await blogAPI.getAllBlogEntries(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetBlogEntryByIdThunk = createAsyncThunk<BlogEntryType, number, AsyncThunkConfig>(
  'blog/getBlogEntryById',
  async (blogEntryId, thunkAPI) => {
    try {
      const { status, data } = await blogAPI.getBlogEntryById(blogEntryId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateBlogEntryThunk = createAsyncThunk<BlogEntryType, NewBlogEntryType, AsyncThunkConfig>(
  'blog/createBlogEntry',
  async (entryData, thunkAPI) => {
    try {
      const mainData = {
        user_id: entryData.user_id,
        title: entryData.title,
        content: entryData.content,
        is_active: entryData.is_active,
        url: entryData.url,
        blog_images: entryData.images.map(img => ({
          image_title: img.file?.originFileObj?.name || '',
          image_html: img.alt,
          comment: '',
          is_cover: img.is_cover
        }))
      }
      const formData = new FormData()
      formData.append('blog_entry', new Blob([JSON.stringify(mainData, null, 2)], {type: 'application/json'}))
      entryData.images.forEach((p) => formData.append('images', p.file.originFileObj))
      const { status, data } = await blogAPI.createBlogEntry(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const EditBlogEntryThunk = createAsyncThunk<BlogEntryType, {blogEntry: NewBlogEntryType, id: number}, AsyncThunkConfig>(
  'blog/editBlogEntry',
  async ({blogEntry, id}, thunkAPI) => {
    try {
      const entryData = {
        user_id: blogEntry?.user_id,
        title: blogEntry.title,
        url: blogEntry.url,
        content: blogEntry.content,
        is_active: true,
        blog_images: blogEntry?.blog_images!
      }
      // @ts-ignore
      const { status, data } = await blogAPI.editBlogEntry(entryData, id)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteBlogEntryThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'blog/deleteBlogEntry',
  async (blogEntryId, thunkAPI) => {
    try {
      const { status, data } = await blogAPI.deleteBlogEntry(blogEntryId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(blogEntryId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const AddImagesToBlogEntryThunk = createAsyncThunk<{entryId: number, imageData: BlogImageType[]}, {entryId: number, imageData: BlogFormImageType[]}, AsyncThunkConfig>(
  'blog/addImagesToBlogEntry',
  async ({entryId, imageData}, thunkAPI) => {
    try {
      const formData = new FormData()
      const mainData = imageData.map(img => ({
        image_title: img.file?.originFileObj?.name || '',
        image_html: img.alt,
        comment: '',
        is_cover: img.is_cover,
        blog_entry_id: img?.blog_entry_id
      }))
      formData.append('blog_images', new Blob([JSON.stringify(mainData, null, 2)], {type: 'application/json'}))
      imageData.forEach((p) => formData.append('images', p.file.originFileObj))
      const { status, data } = await blogAPI.addImagesToBlog(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({entryId, imageData: data.blog_images}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteBlogEntryImagesThunk = createAsyncThunk<{entryId: number, imageIds: number[]}, {entryId: number, imageIds: number[]}, AsyncThunkConfig>(
  'blog/deleteBlogEntryImages',
  async ({entryId, imageIds}, thunkAPI) => {
    try {
      const { status, data } = await blogAPI.deleteImageList(imageIds)
      if (status === 200) {
        return thunkAPI.fulfillWithValue({entryId, imageIds}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetAboutPageThunk = createAsyncThunk<AboutPageType, void, AsyncThunkConfig>(
  'blog/getAboutPage',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await blogAPI.getAboutPage()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.page_sections?.length ? data.page_sections[0] : {} as AboutPageType, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateAboutPageThunk = createAsyncThunk<AboutPageType, {blocks: AboutPageType, images: any[]}, AsyncThunkConfig>(
  'blog/createAboutPage',
  async ({blocks, images}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('page_section', new Blob([JSON.stringify(blocks, null, 2)], {type: 'application/json'}))
      images.forEach((p) => formData.append('images', p?.originFileObj))
      const { status, data } = await blogAPI.createAboutPage(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const UpdateAboutPageThunk = createAsyncThunk<AboutPageType, {parentSectionId: number, parentBlock: AboutPageType, images: any[]}, AsyncThunkConfig>(
  'blog/updateAboutPage',
  async ({parentSectionId, parentBlock, images}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('page_section', new Blob([JSON.stringify(parentBlock, null, 2)], {type: 'application/json'}))
      images.forEach((p) => formData.append('images', p.originFileObj))
      const { status, data } = await blogAPI.updateAboutPage(parentSectionId, formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default blogSlice.reducer
 