import { Form, FormInstance } from 'antd'
import classes from './../AdvertisingDetailsForm.module.css'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetAdvertisementTargetDataThunk, selectCurrentAdvertisement, selectTargetDataPagination, selectTargetDataTotalCount, setTargetDataPagination } from '../../../../store/advertisingReducer'
import ConnectionField from '../../../common/ConnectionField/ConnectionField'
import { ConnectionOptions } from '../../../Services/ServiceDetailsForm/ServiceDetailsForm'
import UserLocationConnectionBlock from '../../../common/ConnectionField/UserLocationConnectionBlock/UserLocationConnectionBlock'
import ConnectionToCruise from '../../../common/ConnectionField/ConnectionToCruise/ConnectionToCruise'
import ConnectionToEvent from '../../../common/ConnectionField/ConnectionToEvent/ConnectionToEvent'
import ConnectionToRental from '../../../common/ConnectionField/ConnectionToRental/ConnectionToRental'

const TargetingAudience: React.FC<TargetingAudiencePropTypes> = ({
  form,
  isEditing,
  isCopy,
  selectedConnectionOption,
  setSelectedConnectionOption,
  setError,
  isTargetingFieldsDisabled
}) => {
  const dispatch = useAppDispatch()
  const currentAdvertisement = useAppSelector(selectCurrentAdvertisement)
  const targetDataPagination = useAppSelector(selectTargetDataPagination)
  const targetDataTotalCount = useAppSelector(selectTargetDataTotalCount)

  const [isLoading, setIsLoading] = useState(false)
  const [addedNodesCount, setAddedNodesCount] = useState(0)
  const [removedNodesCount, setRemovedNodesCount] = useState(0)

  const searchType = Form.useWatch('search_type', form)

  useEffect(() => {
    onConnectionTypeChange()
    // eslint-disable-next-line
  }, [form, searchType])

  const onConnectionTypeChange = () => {
    form.setFieldValue('added_to_copy_node_id_list', [])
    form.setFieldValue('exclude_advertising_location_id_list', [])
    setAddedNodesCount(0)
    setRemovedNodesCount(0)
  }

  const handleNodeSelectForCopiedAds = (nodeId: number | null, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => {
    if (nodeId === null) {
      form.setFieldsValue({exclude_advertising_location_id_list: null})
      form.setFieldValue('advertising_locations', [])
    } else {
      setAddedNodesCount(addedNodesCount + 1)
      const shouldDeleteCopiedNodes = !!removeCopiedNodes
        ? currentAdvertisement?.advertising_locations?.some(l => l.location_type !== 'node') || removeCopiedNodes(currentAdvertisement?.advertising_locations?.map(l => l?.node_type) || [])
        : false
      if (shouldDeleteCopiedNodes) {
        form.setFieldValue('advertising_locations', [])
        setRemovedNodesCount(0)
      }
      const addedNodes = form.getFieldValue('added_to_copy_node_id_list') || []
      form.setFieldsValue({
        added_to_copy_node_id_list: !!shouldDeleteCopiedNodes ? [] : [...addedNodes, nodeId],
        ...(!!shouldDeleteCopiedNodes
          ? {exclude_advertising_location_id_list: null}
          : {}
        )
      })    
    }
  }
  
  const handleAdvertisingLocationIdDeselectForCopiedAds = (nodeId: number) => {
    const excludedLocations: number[] = form.getFieldValue('exclude_advertising_location_id_list') || []
    const locationId = currentAdvertisement?.advertising_locations?.find(loc => loc.node_id === nodeId)?.advertising_location_id
    if (!!locationId) {
      form.setFieldsValue({exclude_advertising_location_id_list: [...excludedLocations, locationId]})
      setRemovedNodesCount(removedNodesCount + 1)
    } else {
      setAddedNodesCount(addedNodesCount - 1)
    }
  }

  return (
    <div className={classes.formBlock}>
      <div className={classes.blockTitle}>
        Targeting Audience
      </div>
      {searchType === 'search' &&
        <ConnectionField
          selectedConnectionOption={selectedConnectionOption}
          setSelectedConnectionOption={setSelectedConnectionOption}
          form={form}
          isEditing={isEditing || isCopy}
          setError={setError}
          currentEditingData={currentAdvertisement}
          itemForConnection='advertisement'
          disabled={isEditing || isTargetingFieldsDisabled}
          handleNodeDeselect={isCopy ? handleAdvertisingLocationIdDeselectForCopiedAds : undefined}
          handleNodeSelect={isCopy ? handleNodeSelectForCopiedAds : undefined}
          onConnectionTypeChange={isCopy ? onConnectionTypeChange : undefined}
          pagination={targetDataPagination.page * targetDataPagination.size >= targetDataTotalCount
            ? undefined
            : {
                loadedCount:  isCopy
                  ? (currentAdvertisement?.advertising_locations?.length || 0) - removedNodesCount + addedNodesCount
                  : currentAdvertisement?.advertising_locations?.length || 0,
                totalCount: isCopy
                  ? targetDataTotalCount + addedNodesCount - removedNodesCount
                  : targetDataTotalCount,
                onLoadMore: () => {
                  setIsLoading(true)
                  dispatch(setTargetDataPagination({...targetDataPagination, page: targetDataPagination.page + 1}))
                  dispatch(GetAdvertisementTargetDataThunk({id: currentAdvertisement?.advertising_id!, pagination: {...targetDataPagination, page: targetDataPagination.page + 1}}))
                    .then(() => setIsLoading(false))
                },
                isLoading: isLoading
              }
          }
        />
      }
      {searchType === 'cruise' &&
        <>
          <ConnectionToCruise 
            itemForConnection='advertisement'
            disabled={isEditing || isTargetingFieldsDisabled}
            form={form}
            isEditing={isEditing}
            currentEditingData={currentAdvertisement}
            handleNodeSelect={isCopy ? handleNodeSelectForCopiedAds : undefined}
          />
          <UserLocationConnectionBlock
            form={form}
            isEditing={isEditing || isCopy}
            currentEditingData={currentAdvertisement}
            blockTitle='Select the option of according to the user’s location:'
            itemForConnection='advertisement'
            disabled={isEditing || isTargetingFieldsDisabled}
          />
        </>
      }
      {searchType === 'event' &&
        <ConnectionToEvent
          form={form}
          isEditing={isEditing || isCopy}
          currentEditingData={currentAdvertisement}
          disabled={isEditing || isTargetingFieldsDisabled}
          handleNodeSelect={isCopy ? handleNodeSelectForCopiedAds : undefined}
        />
      }
      {searchType === 'rental' &&
        <ConnectionToRental
          form={form}
          isEditing={isEditing || isCopy}
          currentEditingData={currentAdvertisement}
          disabled={isEditing || isTargetingFieldsDisabled}
          handleNodeSelect={isCopy ? handleNodeSelectForCopiedAds : undefined}
        />
      }
    </div>
  )
}

interface TargetingAudiencePropTypes {
  form: FormInstance
  isEditing: boolean
  isCopy: boolean
  selectedConnectionOption: number
  setSelectedConnectionOption: (options: ConnectionOptions) => void
  setError: Dispatch<SetStateAction<string>>
  isTargetingFieldsDisabled?: boolean
}

export default TargetingAudience
