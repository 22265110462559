import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { rentalAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { RentalEditedDataType, RentalListFetchParamsType, RentalType } from '../types/rentalType'

const rentalFetchParamsDefaultValue: RentalListFetchParamsType = {
  pagination: {page: 1, size: 10 as 10},
  filter: {
    search_value: ''
  }
}

interface InitialStateType {
  rentalList: null | RentalType[]
  rentalListTotalCount: number
  rentalListFetchParams: RentalListFetchParamsType
  currentRental: null | RentalType
}

const initialState: InitialStateType = {
  rentalList: [],
  rentalListTotalCount: 0,
  rentalListFetchParams: rentalFetchParamsDefaultValue,
  currentRental: null,
}

export const rentalSlice = createSlice({
  name: 'rental',
  initialState,
  reducers: {
    setRentalList: (state, action: PayloadAction<null | RentalType[]>) => {state.rentalList = action.payload},
    setRentalListTotalCount: (state, action: PayloadAction<number>) => {state.rentalListTotalCount = action.payload},
    setRentalListFetchParams: (state, action: PayloadAction<RentalListFetchParamsType>) => {state.rentalListFetchParams = action.payload},
    setCurrentRental: (state, action: PayloadAction<RentalType | null>) => {state.currentRental = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRentalListThunk.fulfilled, (state, action) => {
        state.rentalList = action.payload.short_term_rentals
        state.rentalListTotalCount = action.payload.total_count
      })
      .addCase(GetRentalByCodeThunk.fulfilled, (state, action) => {
        state.currentRental = action.payload
      })
      .addCase(DeleteRentalThunk.fulfilled, (state, action) => {
        state.rentalList = state.rentalList?.filter(rental => rental.code !== action.payload) || []
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.rentalList = null
        state.rentalListTotalCount = 0
        state.rentalListFetchParams = rentalFetchParamsDefaultValue
        state.currentRental = null
      })
  }
})

export const {
  setRentalList,
  setRentalListTotalCount,
  setRentalListFetchParams,
  setCurrentRental,
} = rentalSlice.actions

export const selectRentalList = (state: RootState): null | RentalType[] => state.rental.rentalList
export const selectRentalListTotalCount = (state: RootState): number => state.rental.rentalListTotalCount
export const selectRentalListFetchParams = (state: RootState): RentalListFetchParamsType => state.rental.rentalListFetchParams
export const selectCurrentRental = (state: RootState): RentalType | null => state.rental.currentRental

export const GetRentalListThunk = createAsyncThunk<{short_term_rentals: RentalType[], total_count: number}, {fetchParams: RentalListFetchParamsType, source: any}, AsyncThunkConfig>(
  'rental/getRentalList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      !!fetchParams?.pagination && formData.append('pagination', new Blob([JSON.stringify(fetchParams.pagination, null, 2)], {type: 'application/json'}))
      formData.append('filter', new Blob([JSON.stringify(fetchParams.filter, null, 2)], {type: 'application/json'}))
      const { status, data } = await rentalAPI.getRentalList(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetRentalByCodeThunk = createAsyncThunk<RentalType, string, AsyncThunkConfig>(
  'rental/getRentalByCode',
  async (rentalCode, thunkAPI) => {
    try {
      const { status, data } = await rentalAPI.getRentalByCode(rentalCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const EditRentalThunk = createAsyncThunk<RentalType, {rentalCode: string, rentalData: RentalEditedDataType}, AsyncThunkConfig>(
  'rental/editRental',
  async ({rentalCode, rentalData}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('short_term_rental', new Blob([JSON.stringify(rentalData.short_term_rental, null, 2)], {type: 'application/json'}))
      rentalData?.add_photos?.forEach((p) => formData.append('add_photos', p.originFileObj))
      rentalData?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: rentalData?.delete_photos}, null, 2)], {type: 'application/json'}))
      const { status, data } = await rentalAPI.editRental(rentalCode, formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteRentalThunk = createAsyncThunk<string, string, AsyncThunkConfig>(
  'rental/deleteRental',
  async (rentalCode, thunkAPI) => {
    try {
      const { status, data } = await rentalAPI.deleteRental(rentalCode)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(rentalCode, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default rentalSlice.reducer
 