import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DeleteRentalThunk, GetRentalListThunk, selectRentalList, selectRentalListFetchParams, selectRentalListTotalCount, setRentalListFetchParams } from '../../store/rentalReducer'
import { RentalType } from '../../types/rentalType'
import classes from './RentalList.module.css'
import { Input } from 'antd'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'

const RentalList = () => {
  const dispatch = useAppDispatch()
  const rentalList = useAppSelector(selectRentalList)
  const rentalListTotalCount = useAppSelector(selectRentalListTotalCount)
  const fetchParams = useAppSelector(selectRentalListFetchParams)

  const deleteItem = (rental: RentalType): void => {
    dispatch(DeleteRentalThunk(rental.code))
  }

  const editItemLink = `/rentals/edit/`

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetRentalListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      dispatch(setRentalListFetchParams({
        ...fetchParams,
        filter: {...fetchParams.filter, search_value: searchRequest},
        pagination: {...fetchParams.pagination!, page: 1}
      }))
    }, 350),
    [fetchParams]
  )

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Rentals
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams?.filter?.search_value || '')}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      <ItemList
        items={rentalList || []}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => dispatch(setRentalListFetchParams({...fetchParams, pagination}))}
        total={rentalListTotalCount}
        columnList={['actions']}
        rowIdKey='code'
        getAdditionalColumns={getAdditionalTableColumns}
        deleteItem={deleteItem}
        editItemLink={editItemLink}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Property name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('name', column.sortOrder)
      }),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('location.name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('location.name', column.sortOrder)
      }),
      render: (_:any, item: RentalType) => (<>{item?.location?.name}</>)
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('code'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('code', column.sortOrder)
      }),
    },
  ])
}

export default RentalList
