import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { usersAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { UserListType, UsersFetchParamsType } from '../types/usersTypes'

interface InitialStateType {
  users: UserListType[] | null
  usersTotalCount: number
  usersFetchParams: UsersFetchParamsType
}

const initialState: InitialStateType = {
  users: null,
  usersTotalCount: 0,
  usersFetchParams: {
    search_value: '',
    pagination: {page: 1, size: 10, sort: [{field: 'user_name', direction: 'ASC'}]}
  }
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserListType[]>) => {state.users = action.payload},
    setUsersTotalCount: (state, action: PayloadAction<number>) => {state.usersTotalCount = action.payload},
    setUsersFetchParams: (state, action: PayloadAction<UsersFetchParamsType>) => {state.usersFetchParams = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserListThunk.fulfilled, (state, action) => {
        state.users = action.payload.users
        state.usersTotalCount = action.payload.total_count
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.users = []
      })
  }
})

export const {
  setUsers,
  setUsersTotalCount,
  setUsersFetchParams
} = usersSlice.actions

export const selectUsers = (state: RootState): UserListType[] | null => state.users.users
export const selectUsersTotalCount = (state: RootState): number => state.users.usersTotalCount
export const selectUsersFetchParams = (state: RootState): UsersFetchParamsType => state.users.usersFetchParams

export const GetUserListThunk = createAsyncThunk<{users: UserListType[], total_count: number}, {fetchParams: UsersFetchParamsType, source: any}, AsyncThunkConfig>(
  'users/getUserList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const { status, data } = await usersAPI.getUserList(fetchParams.search_value, fetchParams.pagination, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default usersSlice.reducer
 