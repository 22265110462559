import { ServiceType } from '../types/serviceTypes'

export const getAddedNodes = (serviceData: any, currentService: ServiceType) => {
  return serviceData?.node_id_list?.filter((nId: number) => !currentService?.nodes?.some(node => node?.id === nId))
}

export const getDeletedNodes = (serviceData: any, currentService: ServiceType) => {
  return currentService?.nodes?.filter(n => !serviceData?.node_id_list?.some((nId: number) => nId === n.id)).map(n => n.id)
}

export const getUpdateSettings = (serviceData: any, currentService: ServiceType) => {
  let add_relations_with_node_ids
  let delete_relations_with_node_ids

  const hadNodes = currentService?.nodes?.length
  const hasNodeIds = serviceData?.node_id_list?.length

  if (hadNodes && hasNodeIds) {
    add_relations_with_node_ids = getAddedNodes(serviceData, currentService)
    delete_relations_with_node_ids = getDeletedNodes(serviceData, currentService)
  } else if (hadNodes && !hasNodeIds) {
    delete_relations_with_node_ids = currentService?.nodes?.map(n => n.id)
  } else if (!hadNodes && hasNodeIds) {
    add_relations_with_node_ids = serviceData?.node_id_list
  }

  return {
    add_relations_with_node_ids,
    delete_relations_with_node_ids,
  }
}

export const getConnectionValidationError = (formValues: any, selectedConnectionOption: number, ConnectionOptions: any, specificNodeType?: string) => {
  const hasNodeIds = formValues?.node_id_list?.length || formValues?.advertising_locations?.some((l:any) => l.location_type === 'node' ||  l.location_type === 'event' || l.location_type === 'rental')
  if (!!specificNodeType) {
    if (!!hasNodeIds) {
      return
    } else if (!hasNodeIds
      && !formValues.is_global
      && formValues.advertising_locations?.[0]?.node_type !== 'Cruise'
      && formValues.advertising_locations?.[0]?.location_type !== 'global'
    ) {
      return `Make sure you added at least one ${specificNodeType}!`
    }
  } else {
    if (selectedConnectionOption === ConnectionOptions.Nodes && !hasNodeIds) {
      return `Make sure you added at least one node!`
    } else if (selectedConnectionOption === ConnectionOptions.Coordinates) {
      const hasSetOfCoordinates = !!formValues?.latitude_south && formValues?.latitude_north && formValues?.longitude_east && formValues?.longitude_west
      const hasCoordinatesWithRadius = !!formValues?.radius_area?.latitude && !!formValues?.radius_area?.longitude && !!formValues?.radius_area?.radius && !!formValues?.radius_area?.unit
      const hasCoordinates = !!formValues?.latitude && !!formValues?.longitude && !!formValues?.address
      const hasAlreadyAddedCoordinatesData = formValues?.advertising_locations?.some((l:any) => l.location_type === 'coordinates')
      return hasSetOfCoordinates || hasCoordinatesWithRadius || hasCoordinates || hasAlreadyAddedCoordinatesData
        ? null
        : 'Make sure you filled in all inputs of selected type of coordinates'
    } else if (selectedConnectionOption === ConnectionOptions.City && !hasNodeIds) {
       return 'Make sure you added at least one city!'
    } else if (selectedConnectionOption === ConnectionOptions.Country && !hasNodeIds) {
      return 'Make sure you selected a country!'
    }
  } 
}

export const getAdvertisingLocationsValue = (formValues:any, isCopy?: boolean) => {
  if (isCopy && !!formValues?.added_to_copy_node_id_list?.length && formValues?.exclude_advertising_location_id_list !== null) {
    return formValues?.added_to_copy_node_id_list?.map((id: number) => ({node_id: id, location_type: "node"}))
  } else if (formValues?.search_type === 'rental') {
    return formValues?.node_id_list?.map((id: number) => ({node_id: id, location_type: "rental"}))
  } else if (formValues?.node_id_list?.length) {
    return formValues.node_id_list?.map((id: number) => ({node_id: id, location_type: "node"}))
  } else if (formValues?.is_global ) {
    return [{location_type: "global", is_global: true}]
  } else {
    return [{
      latitude_south: formValues?.latitude_south,
      longitude_east: formValues?.longitude_east,
      longitude_west: formValues?.longitude_west,
      latitude_north: formValues?.latitude_north,
      latitude: formValues?.latitude,
      longitude: formValues?.longitude,
      address: formValues?.address,
      place_id: formValues?.place_id,
      radius_area: !formValues?.radius_area ? undefined : {
        ...formValues?.radius_area || {},
        ...(formValues?.radius_area?.latitude ? {latitude: Number(formValues?.radius_area?.latitude)} : {}),
        ...(formValues?.radius_area?.longitude ? {longitude: Number(formValues?.radius_area?.longitude)} : {}),
      },
      location_type: "coordinates"
    }]
  }
}
