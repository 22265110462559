import { Checkbox, Form, FormInstance, Input, Select } from 'antd'
import axios from 'axios'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import commonClasses from './../ConnectionField.module.css'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import { AdvertisementEditingType } from '../../../../types/advertisingTypes'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetSpecialOfferTypesThunk, selectSpecialOfferTypes } from '../../../../store/advertisingReducer'
import PhoneInput from 'antd-phone-input'
import { GetRentalListThunk, selectRentalList, setRentalList } from '../../../../store/rentalReducer'

const ConnectionToRental: React.FC<ConnectionToRentalPropTypes> = ({
  form,
  isEditing,
  currentEditingData,
  disabled,
  handleNodeSelect
}) => {
  const dispatch = useAppDispatch()
  const rentals = useAppSelector(selectRentalList)
  const isSpecialOffer = Form.useWatch('is_special_offer', form)

  const [isLoading, setIsLoading] = useState(false)
  const [rentalCode, setRentalCode] = useState('')
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [defaultValueChanged, setDefaultValueChanged] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if(!!rentalCode?.length) {
      dispatch(setRentalList(null))
      setIsLoading(true)
      dispatch(GetRentalListThunk({
        fetchParams: {
          filter: {
            search_value: rentalCode,
          },
        },
        source
      }))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsLoading(false)
        }
      })
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, rentalCode])

  useEffect(() => {
    form.setFieldsValue({node_id_list: []})
    return () => {
      form.setFieldsValue({node_id_list: []})
      setRentalCode('')
      dispatch(setRentalList(null))
    }
    // eslint-disable-next-line
  }, [form])

  const onRentalSelect = (rentalId: number) => {
    handleNodeSelect && handleNodeSelect(rentalId, () => true)
    form.setFieldsValue({node_id_list: [rentalId]})
    setDefaultValueChanged(true)
    setRentalCode('')
  }

  return (
    <>
      <div className={`${commonClasses.blockTitle} ${commonClasses.advertisement}`}>
        Select the rental of according to the rental searched by the user:
      </div>
      <Form.Item>
        <Select
          placeholder='Enter the rental code or name'
          style={{ width: '65%' }}
          suffixIcon={<SearchIcon style={{marginRight: '10px', marginBottom: '3px'}}/>}
          value={
            isEditing && !defaultValueChanged
              ? {value: currentEditingData?.advertising_locations?.[0]?.node_id!, label: `${currentEditingData?.advertising_locations?.[0]?.code} - ${currentEditingData?.advertising_locations?.[0]?.name}`}
              : form.getFieldValue('node_id_list')
          }
          showSearch
          onSearch={(val) => {
            setRentalCode(val)
          }}
          onSelect={(rental:any) => onRentalSelect(rental?.value)}
          filterOption={false}
          labelInValue
          open={isOptionsOpen}
          onDropdownVisibleChange={(isOpen => setIsOptionsOpen(isLoading || isOpen))}
          notFoundContent={isLoading
            ? 'Loading...'
            : rentals === null ? 'Start typing rental code or name' : 'No rental options found'}
          disabled={disabled}
          searchValue={rentalCode}
        >
          {sortBy(rentals, rental => rental.name).map(rental => (
            <Select.Option value={rental.id} key={rental.id}>
              {rental.code} - {rental.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'is_special_offer'} valuePropName='checked'>
        <Checkbox disabled={disabled}>
          Special offers
        </Checkbox>
      </Form.Item>
      {isSpecialOffer &&
        <SpecialOfferFields 
          form={form}
          isEditing={isEditing}
          currentEditingData={currentEditingData}
          disabled={disabled}
          handleNodeSelect={handleNodeSelect}
          />
      }
    </>
  )
}
const SpecialOfferFields: React.FC<ConnectionToRentalPropTypes> = ({form, isEditing, currentEditingData, disabled}) => {
  const dispatch = useAppDispatch()
  const specialOfferTypes = useAppSelector(selectSpecialOfferTypes)

  useEffect(() => {
    if (specialOfferTypes === null) {
      dispatch(GetSpecialOfferTypesThunk())
    }
  }, [dispatch, specialOfferTypes])

  const phoneValidator = (_: any, {valid}: {valid: () => boolean}) => {
    if (!valid || valid()) return Promise.resolve()
    return Promise.reject('Invalid phone number')
  }

  return (
    <>
      <div className={commonClasses.blockTitle}>
        Choose Type
      </div>
      <Form.Item
        name={['special_offer', 'offer_type_id']}
        rules={[{ required: true, message: 'Please select offer type!' }]}
      >
        <Select
          placeholder='Choose Type'
          style={{ width: '100%' }}
          options={sortBy(specialOfferTypes, t => t.name)?.map(t => ({label: t.name, value: t.id, data: t}))}
          filterOption={false}
          labelInValue
          onSelect={v => form.setFieldValue(['special_offer', 'offer_type_id'], v?.value)}
        />
      </Form.Item>

      <div className={commonClasses.blockTitle}>
        Discount details
      </div>
      <Form.Item
        name={['special_offer', 'discount_info']}
        // rules={[{ required: true, message: 'Please select offer type!' }]}
      >
        <Input placeholder='Enter Discount details (Example: 10% discount) ' />
      </Form.Item>

      <div className={commonClasses.blockTitle}>
        Phone number
      </div>
      <Form.Item
        name={['special_offer', 'phone']}
        rules={[{validator: phoneValidator, message: 'Wrong format!' }]}
      >
         <PhoneInput />
      </Form.Item>
    </>
  )
}

interface ConnectionToRentalPropTypes {
  form: FormInstance
  isEditing?: boolean
  currentEditingData?: AdvertisementEditingType | null
  disabled?: boolean
  handleNodeSelect?: (id: number, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
}

export default ConnectionToRental
