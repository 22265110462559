import { Button, Checkbox, Form, Input, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './RentalDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import axios from './../../../helpers/axiosHelper'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import { geocodeByAddress } from 'react-places-autocomplete'
import { EditRentalThunk, GetRentalByCodeThunk, selectCurrentRental, setCurrentRental } from '../../../store/rentalReducer'
import { RentalEditedDataType } from '../../../types/rentalType'
import ImagesField from './ImagesField/ImagesField'
import { uniqBy } from 'lodash'
import moment from 'moment'

const RentalDetailsForm = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentRental = useAppSelector(selectCurrentRental)
  const [form] = Form.useForm()

  const urlRegex = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+([/\w\-.~:?#[\]@!$&'()*+,;=]*)*$/

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [locationValidationError, setLocationValidationError] = useState('')
  const [locationValue, setLocationValue] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    // pathname example: /rentals/edit/111
    const code = location.pathname.split('/edit/')[1]
    setIsDataLoading(true)
    dispatch(GetRentalByCodeThunk(code))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setCurrentRental(null))
    }
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue({
      short_term_rental: {
        name: currentRental?.name || '',
        description: currentRental?.description || '',
        url: currentRental?.url || '',
        location: currentRental?.location
      }
    })
    // currentRental?.photo_urls?.length && setImagePreviews(currentRental?.photo_urls.map(url => ({url})))
    !!currentRental?.location && setLocationValue(currentRental?.location?.name)
  }, [currentRental, form])

  const handleLocationChange = async(value: string) => {
    const formValues = form.getFieldsValue(true)
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const locationData = {
        name: value,
        country_code: addressData[0]?.address_components?.find(c => c.types.includes('country'))?.short_name || '',
        country_name: addressData[0]?.address_components?.find(c => c.types.includes('country'))?.long_name || '',
        city_name: addressData[0]?.address_components?.find(c => c.types.includes('locality'))?.long_name || '',
        coordinates: {
          latitude: addressData[0].geometry.location.lat(),
          longitude: addressData[0].geometry.location.lng()
        }
      }
      form.setFieldsValue({short_term_rental: {...formValues.short_term_rental, location: locationData}})
      setLocationValue(value)
      setLocationValidationError('')
    } else {
      form.setFieldsValue({short_term_rental: {...formValues.short_term_rental, location: null}})
      setLocationValue('')
    }
  }

  const getBaseUrl = () => {
    if (window.location.origin?.includes('scopia-admin-dev.esverito.com')) {
      return 'https://scopia-user-dev.esverito.com'
    } else if (window.location.origin?.includes('scopia-admin-test.esverito.pl')) {
      return 'https://scopia-user-test.esverito.com'
    } else {
      return 'https://www.relavanti.com'
    }
  }

  const onSubmit = async() => {
    const formData: RentalEditedDataType = form.getFieldsValue(true)
    const rentalData = {
      ...formData,
      add_photos: uniqBy(formData?.add_photos, 'uid'),
    }
    if (!rentalData?.short_term_rental?.location?.name) {
      setLocationValidationError('Please select location!')
      return
    }
    setIsSaving(true)
    dispatch(EditRentalThunk({rentalCode: currentRental?.code!, rentalData }))
      .then((resp) => {
        setIsSaving(false)
        if(!resp?.type.includes('rejected')) {
          navigate('/rentals')
        }
      })
  }

  if (isDataLoading || !currentRental?.id) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1>
          {currentRental?.name}
        </h1>
      </div>

      <Form
        name='editRental'
        autoComplete='off'
        validateTrigger='onBlur'
        form={form}
        onFinish={onSubmit}
        onFinishFailed={() => {
          const formValues = form.getFieldsValue(true)
          if (!formValues?.location?.name) {
            setLocationValidationError('Please select location!')
            return
          }
        }}
      >
        <div className={classes.mainInfoArea}>
          <div className={classes.formBlock}>
            <div className={classes.field}>
              <div className={classes.label}>Property Name</div>
              <Form.Item
                name={['short_term_rental', 'name']}
                rules={[{ required: true, message: 'Please enter property name!' }]}
              >
                <Input placeholder='Enter property name'/>
              </Form.Item>
            </div>
   
            <div className={classes.field}>
              <div className={classes.label}>Property Link</div>
              <Form.Item
                name={['short_term_rental', 'url']}
                rules={[
                  { required: true, message: 'Please enter property link!' },
                  { pattern: urlRegex, message: 'Invalid format!' }
                ]}
              >
                <Input placeholder='Enter property link'/>
              </Form.Item>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Property Location</div>
              <InputGoogleAPI
                placeholder='Enter location'
                status={!!locationValidationError.length ? 'error' : undefined}
                value={locationValue || ''}
                onChange={(value) => handleLocationChange(value)}
              />
              <div className={classes.validationError}>
                {locationValidationError}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>About Property</div>
                <Form.Item name={['short_term_rental', 'description']}>
                  <Input.TextArea
                    placeholder='Describe your property'
                    showCount
                    maxLength={360}
                  />
                </Form.Item>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>
                Terms and Conditions
              </div>
              <div className={classes.termsAndConditions}>
                <Checkbox disabled checked> 
                  <span style={{marginLeft: '8px'}}>
                    Terms and Conditions are accepted
                  </span>
                </Checkbox>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                  <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)'}}>
                    User IP: {currentRental?.user_ip}
                  </div>
                  <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)', marginLeft: '40px'}}>
                    Date: {moment(currentRental?.date_created).format('DD.MM.YYYY HH:mm')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.formBlock}>
            <div className={classes.field}>
              <div className={classes.label}>User name</div>
              <div>
                {currentRental?.user_name}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>User email</div>
              <div>
                {currentRental?.user_email}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Rental Code</div>
              <div>
                {currentRental?.code}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Relavanti Link</div>
              <div>
                {getBaseUrl() + '/rentals/details/' + currentRental?.code}
              </div>
            </div>
          </div>
        </div>
      </Form>

      <ImagesField form={form} />

      <div className={classes.buttons}>
        <Link to='/rentals'>
          <Button>
            Cancel
          </Button>
        </Link>
        <Button
          type='primary'
          onClick={() => form.submit()}
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default RentalDetailsForm
