import { DatePicker, Select } from 'antd'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import axios from './../../helpers/axiosHelper'
import { selectTrackingFetchParams, setTrackingFetchParams } from '../../store/appStatusReducer'
import { GetAllTrackingsThunk, selectTrackings, selectTrackingsTotalCount } from '../../store/trackingsReducer'
import ItemList from '../common/ItemList/ItemList'
import classes from './TrackingList.module.css'
import { TrackingFetchParamsType } from '../../types/trackingTypes'

const TrackingList = () => {
  const dispatch = useAppDispatch()
  const trackings = useAppSelector(selectTrackings)
  const trackingsTotalCount = useAppSelector(selectTrackingsTotalCount)
  const fetchParams = useAppSelector(selectTrackingFetchParams)

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllTrackingsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  const setFetchParams = (params: TrackingFetchParamsType) => {
    dispatch(setTrackingFetchParams(params))
  }

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Tracking data
        </h1>
        <div className={classes.tools}>
          <Select
            value={fetchParams.searched_type}
            onChange={(val) => setFetchParams({...fetchParams, searched_type: val})}
            style={{width: '175px', marginRight: '10px'}}
          >
            <Select.Option value={'Search'}>General</Select.Option>
            <Select.Option value={'Flight'}>By Flight</Select.Option>
            <Select.Option value={'Cruise'}>By Cruise</Select.Option>
            <Select.Option value={'Event'}>By Event</Select.Option>
            <Select.Option value={'SHORT_TERM_RENTAL'}>By Rental</Select.Option>
          </Select>
          <DatePicker.RangePicker
            value={[moment(fetchParams.start_period), moment(fetchParams.end_period)]}
            onChange={val => setFetchParams({...fetchParams, pagination: {...fetchParams.pagination, page: 1}, start_period: moment(val![0]).format('YYYY-MM-DD'), end_period: moment(val![1]).format('YYYY-MM-DD')})}
            allowClear={false}
            ranges={{
              "Today": [moment(), moment()],
              "Yesterday": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              "Current Week": [moment().startOf('week'), moment()],
              "Last Week": [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
              "Current Month" : [moment().startOf('month'), moment()] ,
              "Last Month" : [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
            }}
          />
        </div>
      </div>
      <ItemList
        items={trackings}
        // editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={trackingsTotalCount}
        columnList={[
          'action_date',
          'computer_city',
          ...(fetchParams.searched_type === 'Search' ? ['searchedAddress'] : []),
          ...(fetchParams.searched_type === 'Flight' ? ['startLocation', 'endLocation'] : []),
          ...(fetchParams.searched_type === 'Cruise' ? ['startLocation', 'endLocation'] : []),
        ]}
        getAdditionalColumns={
          fetchParams.searched_type === 'Search'
            ? undefined
            : (getColumnSortOrder, setColumnSortOrder) => getAdditionalTrackingColumns(fetchParams.searched_type, getColumnSortOrder, setColumnSortOrder)
        }
        rowIdKey='tracking_id'
      />
    </div>
  )
}

const getAdditionalTrackingColumns: GetAdditionalColumnType = (searched_type, getColumnSortOrder, setColumnSortOrder) => {
  return ([
    ...(searched_type === 'Flight' ? [
      {
        title: 'Airline',
        dataIndex: 'searched_airline',
        key: 'searched_airline',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_airline'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_airline', column.sortOrder)
        }),
        ellipsis: true,
      },
      {
        title: 'Flight Number',
        dataIndex: 'searched_flight_number',
        key: 'searched_flight_number',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_flight_number'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_flight_number', column.sortOrder)
        }),
        ellipsis: true,
      },
      {
        title: 'Flight Date',
        dataIndex: 'searched_flight_date',
        key: 'searched_flight_date',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_flight_date'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_flight_date', column.sortOrder)
        }),
        ellipsis: true,
      }
    ] : []),
    ...(searched_type === 'Cruise' ? [
      {
        title: 'Cruise Line',
        dataIndex: 'searched_cruise_line',
        key: 'searched_cruise_line',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_cruise_line'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_cruise_line', column.sortOrder)
        }),
        ellipsis: true,
      },
      {
        title: 'Cruise Name',
        dataIndex: 'searched_cruise_name',
        key: 'searched_cruise_name',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_cruise_name'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_cruise_name', column.sortOrder)
        }),
        ellipsis: true,
      },
      {
        title: 'Start Date',
        dataIndex: 'searched_cruise_first_date',
        key: 'searched_cruise_first_date',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_cruise_first_date'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_cruise_first_date', column.sortOrder)
        }),
        ellipsis: true,
      },
    ] : []),
    ...(searched_type === 'Event' ? [
      {
        title: 'Code',
        dataIndex: 'event_code',
        key: 'event_code',
        sorter: true,
        sortOrder: getColumnSortOrder('event_code'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('event_code', column.sortOrder)
        }),
        ellipsis: true,
      }, {
        title: 'Event Location',
        dataIndex: 'event_location_name',
        key: 'event_location_name',
        // sorter: true,
        // sortOrder: getColumnSortOrder('event_location_name'),
        // onHeaderCell: (column: any) => ({
        //   onClick: () => setColumnSortOrder('event_location_name', column.sortOrder)
        // }),
        ellipsis: true,
      }
    ] : []),
    ...(searched_type === 'SHORT_TERM_RENTAL' ? [
      {
        title: 'Code',
        dataIndex: 'short_term_rental_code',
        key: 'short_term_rental_code',
        sorter: true,
        sortOrder: getColumnSortOrder('short_term_rental_code'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('short_term_rental_code', column.sortOrder)
        }),
        ellipsis: true,
      }, {
        title: 'Property Location',
        dataIndex: 'short_term_rental_location_name',
        key: 'short_term_rental_location_name',
        // sorter: true,
        // sortOrder: getColumnSortOrder('event_location_name'),
        // onHeaderCell: (column: any) => ({
        //   onClick: () => setColumnSortOrder('event_location_name', column.sortOrder)
        // }),
        ellipsis: true,
      }
    ] : []),
    ...(searched_type !== 'SHORT_TERM_RENTAL' && searched_type !== 'Event' ? [
      {
        title: 'Start Location',
        dataIndex: 'searched_start_destination_city',
        key: 'searched_start_destination_city',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_start_destination_city'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_start_destination_city', column.sortOrder)
        }),
        ellipsis: true,
      },
      {
        title: 'End Location',
        dataIndex: 'searched_final_destination_city',
        key: 'searched_final_destination_city',
        sorter: true,
        sortOrder: getColumnSortOrder('searched_final_destination_city'),
        onHeaderCell: (column: any) => ({
          onClick: () => setColumnSortOrder('searched_final_destination_city', column.sortOrder)
        }),
        ellipsis: true,
      }
    ] : []),
  ])
}

type GetAdditionalColumnType = (
  searched_type: 'Flight' | 'Cruise' | 'Search' | 'Event' | 'SHORT_TERM_RENTAL',
  getColumnSortOrder: (columnName: string) => false | 'descend' | 'ascend',
  setColumnSortOrder: (columnName: string, currentSortOrder: 'descend' | 'ascend' | false) => void
) => any[]

export default TrackingList
